import axios from 'axios'

export default {
  data: function() {
    return {
      checkingAddress: undefined,
      searchInProgress: false,
      addressFound: undefined,
      searchDialog: false,
      progress: 0
    }
  },
  methods: {
    async searchPrinter(timeout=500) {
      this.addressFound = undefined
      this.searchInProgress = true
      this.searchDialog = true
      const subnet = '192.168.1'

      for(let i = 1; i <= 255; i += 1) {
        let address = `${subnet}.${i}`
        this.checkingAddress = address
        try {
          this.progress = (100 / 255) * i
          let response = await axios.post(`https://${address}/cgi-bin/fpmate.cgi?devid=local_printer&timeout=10000`, `
          <?xml version="1.0" encoding="utf-8"?>
            <s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
                <s:Body>
                    <fpMateConfiguration><readVersion/></fpMateConfiguration>
                </s:Body>
            </s:Envelope>
          `, {
            headers: {
              'Content-Type': 'text/xml'
            },
            timeout: timeout
          })
          this.searchInProgress = false
          this.addressFound = address
          break
        } catch(error) {
        }
      }
      this.searchInProgress = false
    },
    closeSearchDialog() {
      this.searchDialog = false
    }
  },
  computed: {
    dialogTitle() {
      if (this.searchInProgress) {
        return 'Ricerca in corso ...'
      } else if (this.addressNotFound) {
        return 'Indirizzo non trovato :('
      } else {
        return 'Indirizzo trovato'
      }
    },
    addressNotFound() {
      return !this.addressFound
    }
  }
}